
$templatemode: true;


$bgColor: #fff;
$white: #fff;

$primary-color: #19042f;
$main-color: #c21617;
$font-color: #1c1c1c;
$logo-position: left; // left | right

$header-color-mobile: #fff;

$header-navigation-color: transparent;

$normal-font: 'Open Sans', sans-serif;
$headline-font: 'Roboto', sans-serif;


$social-media-box-size: 40px;
$social-media-box-fontsize: 1.2em;

$primary-bg-color: #5524a3;
$bit-gradient: #d600fe 0%,#d106fe 5%,#cb09fd 7%,#cb09fd 9%,#c80bfb 9%,#c70ffd 11%,#c50ffa 12%,#c015fa 16%,#ba18f9 17%,#ba19fa 18%,#b120f7 21%,#b520f8 21%,#b31ff7 21%,#b221f8 22%,#ab25f6 24%,#a52af4 26%,#a92af7 26%,#a62df6 28%,#a22ef3 29%,#9d32f2 30%,#a131f5 30%,#9a33f2 32%,#953bf2 34%,#8c44f0 38%,#8844ef 38%,#8a47ef 39%,#8847ed 39%,#8548ed 40%,#8649ef 40%,#7d51ec 44%,#7854ea 45%,#7856eb 46%,#7259e9 47%,#6c62e9 50%,#6961e8 51%,#6764e9 51%,#6665e7 53%,#5a6fe6 56%,#5a71e5 57%,#5770e4 57%,#5a71e5 57%,#5573e3 58%,#4981e2 64%,#3591de 70%,#388ddd 70%,#3692dd 71%,#2f95dd 73%,#2f98da 74%,#279fdb 78%,#23a0da 78%,#25a0db 78%,#12b0d5 87%,#11b1d5 88%,#13b1d8 88%,#0db6d5 91%,#0ab8d3 92%,#00c1d2 100%;
$horizontal-gradient: linear-gradient(to right, $bit-gradient);
$vertical-gradient: linear-gradient(to bottom, $bit-gradient);

$mobile-nav-position: left; // left | center

$add-border-after-header: true;
$border-after-header: 2px solid $main-color;

@import "functions";