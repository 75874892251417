@import "../../css/config";

#top_info_line {
	background: $main-color;
	color: #fff;
	text-align: center;
	font-size: 0.9em;
	line-height: 2em;

	a {
		color: #fff;

		&:hover, &:focus {
			color: #fff;
		}
	}
}
